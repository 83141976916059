/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lodash@4.17.21/lodash.min.js
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/vue@2.6.14/dist/vue.min.js
 * - /npm/vue-router@3.6.5/dist/vue-router.min.js
 * - /npm/axios@0.19.2/dist/axios.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.bundle.min.js
 * - /npm/marked@0.8.2/marked.min.js
 * - /npm/sortablejs@1.10.2/Sortable.min.js
 * - /npm/echarts@4.6.0/dist/echarts.min.js
 * - /npm/vuedraggable@2.23.2/dist/vuedraggable.umd.min.js
 * - /npm/bootstrap-datepicker@1.9.0/dist/js/bootstrap-datepicker.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
